import './App.css';
import {useState, useEffect} from 'react';
import {ThemeProvider} from "@mui/material";
import {GridRowSelectionModel} from '@mui/x-data-grid';
import {styled} from "styled-components";
import {Navbar} from "twd-react-components";
import CircularProgress from "@mui/material/CircularProgress";
import twdTheme from './styles/CustomMaterialTheme';
import InvoiceTable from './components/InvoiceTable';
import {RowEntry} from './components/InvoiceTableType';
import ProcessButton from './components/ProcessButton';
import {getFromFlask} from "./services/apiCalls";
import {styled as styledMui} from "@mui/material/styles";


const ScreenDiv = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: var(--twd_grey);
    display: flex;
    justify-content: center;
`;

// Styling for the Box containing the whole app.
const AppDiv = styled.div`
    width: calc(100% - 60px);
    height: calc(100% - 150px);
    margin-top: 70px;
    border-radius: 10px; // round corners of div containing invoice table
    background-color: var(--twd_white);
`;

const StyledCircularProgress = styledMui(CircularProgress)({
    position: 'absolute',
    top: '50%',
    left: '50%'
});


function App() {
    // Step 1: Initialize the rows state with an empty array
    const [rows, setRows] = useState<RowEntry[]>([]);
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [processed, setProcessed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true); // State used to show a loading circle when loading invoices data

    // Step 2: Use `useEffect` to fetch the initial data at the component creation
    useEffect(() => {
        async function fetchInitialRows() {
            const initialRows = await getInitialRows();
            setRows(initialRows);  // Step 3: Update the state with the fetched data
            setLoading(false); // Set loading to false once data is fetched
        }

        fetchInitialRows();
    }, []); // Empty dependency array ensures this effect runs only when loading the page


    // Get invoices to process with API call
    async function getInitialRows(): Promise<RowEntry[]> {
        const response = await getFromFlask("/invoicing-tool/invoices");
        const responseJson = await response.json();
        const responseData = responseJson.data;

        // Convert invoice_date strings into Date objects
        const formattedData = responseData.map((entry: any) => ({
            ...entry,
            invoice_date: new Date(entry.invoice_date), // Convert to Date object to sort and filter in the app
            processed: null // Nothing is processed when loading the page
        }));

        return formattedData;
    }

    return (
        <ThemeProvider theme={twdTheme}>
            <ScreenDiv>
                <Navbar title={"Invoicing Tool"}></Navbar>
                <AppDiv className={"app"}>
                    {loading ? (
                        <StyledCircularProgress/> // Show loading spinner while data is loading
                    ) : (
                        <>
                            <InvoiceTable
                                rows={rows}
                                setRows={setRows}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                processed={processed}
                                setProcessed={setProcessed}
                            />
                            <ProcessButton
                                rows={rows}
                                setRows={setRows}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                processed={processed}
                                setProcessed={setProcessed}
                            />
                        </>
                    )}
                </AppDiv>
            </ScreenDiv>
        </ThemeProvider>
    );
}

export default App;
import React from 'react';
import {Alert, Tooltip, Typography, TextField, Box} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import {useState} from 'react';
import {styled as styledMui} from "@mui/material/styles"
import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridPagination
} from '@mui/x-data-grid';
import {RowEntry, InvoiceTableProps, FooterDataGridProps} from "./InvoiceTableType";
import {twdTheme} from "../styles/CustomMaterialTheme";

// Styled components
const StyledDataGrid = styledMui(DataGrid)<any>(({disabledCheckBox}) => ({
    "& .MuiDataGrid-checkboxInput": {
        color: disabledCheckBox ? "var(--twd_grey)" : twdTheme.palette.primary.main,
    },
    // Remove borders around the cells when clicking
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
    // Removes borders around the headers when clicking
    "& .MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
    },
}));

const StyledTextField = styledMui(TextField)({
    width: "140px",
    marginTop: "6px"
});

const StyledAlert = styledMui(Alert)({
    width: "100%",
    padding: "8px 6px"
});

const StyledBoxFooter = styledMui(Box)({
    color: "var(--twd_blue)",
    fontSize: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "18px"
})

function CustomFooterDataGrid(props: FooterDataGridProps) {
  return (
    <StyledBoxFooter>
        {/* Custom text on the left */}
          {props.countSelectedRows}/{props.countRows} rows selected
          &nbsp;&nbsp;&nbsp;&nbsp; {/* Adds 4 spaces */}
          Total selected rows: {new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(props.sumSelectedRows)}
        {/* Default pagination on the right */}
        <GridPagination />
    </StyledBoxFooter>
  );
}

export default function InvoiceTable(props: InvoiceTableProps) {

    // Billing weeks header value to copy over empty billing weeks cells
    const [billingWeeksHeader, setBillingWeeksHeader] = useState<string>("");

    // Initialize countSelectedRows and sumSelectedRows
    const [countSelectedRows, setCountSelectedRows] = useState<number>(0);
    const [sumSelectedRows, setSumSelectedRows] = useState<number>(0);
    const countRows = props.rows.length;

    // Function to calculate the sum of selected rows
    const calculateSumSelectedRows = (selectedRowIds: GridRowSelectionModel) => {
        const selectedRows = props.rows.filter(row => selectedRowIds.includes(row.invoice_number)); // Getids of selected rows id
        const sum = selectedRows.reduce((total, row) => total + (row.total_amount_excl_vat || 0), 0);   // Calculate sum of selected rows
        setSumSelectedRows(sum);
        setCountSelectedRows(selectedRows.length);
    };


    // Invoice table columns definition
    const initialColumns: GridColDef<RowEntry>[] = [
        {
            field: "invoice_number",
            headerName: "Project invoice",
            minWidth: 140,
            flex: 1.2,
        },
        {
            field: "invoice_date",
            headerName: "Date",
            type: "date",
            minWidth: 96,
            flex: 1.05,
            sortable: true,
            valueFormatter: (params) => {
                return new Intl.DateTimeFormat("en-GB").format(params);
            },  // Date format converison to be able to sort/filter the date in the App
        },
        {
            field: "project_number",
            headerName: "Project number",
            minWidth: 145,
            flex: 1.5,
            sortable: true,
        },
        {
            field: "end_client_name",
            headerName: "End client",
            minWidth: 150,
            flex: 2.2,
            sortable: true,
        },
        {
            field: "total_amount_excl_vat",
            headerName: "Total",
            type: "number",
            sortable: true,
            minWidth: 110,
            flex: 1,
            valueFormatter: (value, context) => {
                const currencySymbol = context.currency_symbol || "€"; // Use the currency symbol column value or "€" if not defined
                return currencySymbol + " " + new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(value);
            },
        },
        {
            field: "billing_weeks",
            headerName: "Billing Weeks",
            width: 160,
            sortable: false,
            disableColumnMenu: true, // Completely remove the column menu
            renderHeader: () => (
                <StyledTextField
                    size="small"
                    label="Billing weeks"
                    value={billingWeeksHeader}
                    disabled={props.processed}
                    onChange={(event) => {
                        const newHeaderValue = event.target.value;
                        // Update the header value
                        setBillingWeeksHeader(newHeaderValue);
                        // Update all rows with the new header value, if the row's billing_weeks matches the old header value or is empty
                        const updatedRows = props.rows.map((row) => {
                            if (!row.billing_weeks || row.billing_weeks === billingWeeksHeader) {
                                return { ...row, billing_weeks: newHeaderValue };
                            }
                            return row;
                        });
                        props.setRows(updatedRows);
                    }}  // Update rows with changed billing weeks value from header
                />
            ),
            renderCell: (params) => (
                <StyledTextField
                    size="small"
                    value={params.row.billing_weeks || billingWeeksHeader}  // Use cell value or header value if empty
                    onKeyDown={(e) => {
                        e.key === " " && e.stopPropagation();
                    }}  // Allow typing space in text field and do not go to next row
                    disabled={props.processed}
                    onChange={(event) => {
                        const updatedRows = props.rows.map((row) => {
                            if (row.invoice_number === params.id) {
                                return {...row, billing_weeks: event.target.value};
                            }
                            return row;
                        });
                        props.setRows(updatedRows);
                    }}  // Update rows with changed billing weeks value
                />
            ),
        },
        {
            field: "processed",
            headerName: "Processed",
            width: 113,
            type: "boolean",
            renderCell: (params) => {
                if (params.value === true) {
                    return <DoneIcon sx={{color: "var(--twd_blue)"}}/>;  // Blue done icon if processed
                } else if (params.value === false) {
                    return <ErrorOutlinedIcon sx={{color: "var(--twd_red)"}}/>; // Red error icon if error during processing
                } else {
                    return <DoneIcon sx={{color: "var(--twd_grey)"}}/>;  // Grey done icon if not processed
                }
            },
        },
        {
            field: "counter_direct_invoice",
            headerName: "Counter direct invoice",
            minWidth: 186,
            flex: 1.6,
            sortable: true,
        },
        {
            field: "comments",
            headerName: "Comments",
            sortable: true,
            minWidth: 150,
            flex: 3.5,
            renderCell: (params) => {
                if (params.value) {
                    return (
                        <Tooltip title={params.value} arrow>
                            <StyledAlert severity="error">
                                <Typography variant="body2" noWrap>
                                    {params.value}
                                </Typography>
                            </StyledAlert>
                        </Tooltip>
                    )
                }
            }  // Red background and error icon if error during processing
        }
    ];

    return (
        <StyledDataGrid
            rows={props.rows} // Rows definition
            columns={initialColumns}  // Columns definition
            disableColumnSelector   // Disable hide and manage columns
            disabledCheckBox={props.processed}  // Used to change the checkbox color
            getRowId={(row: RowEntry) => row.invoice_number}  // Use invoice number column as row id
            checkboxSelection // Add check box selection
            disableRowSelectionOnClick  // Disable selection by clicking on a row
            rowSelectionModel={props.selectedRows}  // Selected rows definition
            onRowSelectionModelChange={(newSelection: GridRowSelectionModel) => {
                if (!props.processed) {
                    props.setSelectedRows(newSelection);
                    calculateSumSelectedRows(newSelection)
                } // Update selected rows only when processed state is false
            }}
            slots={{
                footer: CustomFooterDataGrid,
            }}
            slotProps={{
                footer: {countSelectedRows, sumSelectedRows, countRows},
            }}
        />
    );
}
import React from 'react';
import Button from "@mui/material/Button";
import LoopIcon from "@mui/icons-material/Loop";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {styled as styledMui} from "@mui/material/styles";
import {ButtonTextType, ProcessButtonProps} from './ProcessButtonType';
import {RowEntry} from "./InvoiceTableType";
import {postFromFlask} from "../services/apiCalls";


const StyledButton = styledMui(Button)({
    width: "180px",
    height: "50px",
    fontSize: "1.1rem",
    left: "50%",
    transform: "translateX(-50%)",
    marginTop: '15px'
});

const StyledTypography = styledMui(Typography)({
    position: 'absolute',
    marginTop: '-128px',
});

const StyledBackdrop = styledMui(Backdrop)({
    zIndex: 1300, // Higher than DataGrid z-index to overlay it
});


export default function ProcessButton(props: ProcessButtonProps) {
    const [buttonText, setButtonText] = React.useState<ButtonTextType>("Process");
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    // Action triggered by clicking on "Process" button
    const handleClickProcess = () => {
        setButtonText("Processing..."); // Update button text when processing
        setIsProcessing(true);  // Update state to trigger opening backdrop

        // API call to process invoices and await response to get processed rows from backend
        // Filter rows to include only those whose invoice_number is in selectedRows
        const invoices = props.rows
            .filter(row => props.selectedRows.includes(row.invoice_number))
            .map(row => ({
                invoice_number: row.invoice_number, // id is assumed to be invoice_number here
                billing_weeks: row.billing_weeks || "" // Use an empty string if billing_weeks is undefined
            }));
        postFromFlask("/invoicing-tool/process",
            {
                "invoices": invoices
            }
        ).then(async (response) => {
            const responseJson = await response.json();
            const responseData = responseJson.data;
            handleEndProcessing(responseData);  // Update invoice table, close backdrop, update button text
        });
    };

    // Action triggered after receiving processed rows from API
    //  - update invoice table with processed rows
    //  - close backdrop
    //  - update button text
    const handleEndProcessing = (processedData: RowEntry[]) => {
        const updatedRows = props.rows.map((row) => {
            // Check if the row is selected
            if (props.selectedRows.includes(row.invoice_number)) {
                // Find the corresponding processed row
                const processedRow = processedData.find((processed) => processed.invoice_number === row.invoice_number);
                // If a processed row is found, merge processed row data with the current row data
                if (processedRow) {
                    return {...row, ...processedRow};
                }
            }
            // If no processed row is found, or the row is not selected, return the original row
            return row;
        });
        props.setRows(updatedRows); // Update the rows with the merged data
        props.setProcessed(true); // Update processed state after processing to disable selection
        setIsProcessing(false); // Update state to trigger closing backdrop
        // Update button text after processing and add loop icon
        setButtonText("Refresh List");
    };

    // Action triggered by clicking on "Refresh List" button
    const handleClickRefresh = () => {
        window.location.reload(); // Refresh page
    };


    return (
        <>
            {/* Backdrop with loading page when processing*/}
            <StyledBackdrop open={isProcessing}>
                <StyledTypography variant="h5">
                    {`Creating ${props.selectedRows.length} counter direct invoice${props.selectedRows.length > 1 ? 's' : ''}...`}
                </StyledTypography>
                <CircularProgress/>
            </StyledBackdrop>

            {/* Button to process invoices*/}
            <StyledButton
                // Disable button clicking when no row is selected or isProcessing state is true
                disabled={props.selectedRows.length === 0 || isProcessing}
                // If processed state is false, handleClickProcess, otherwise handleClickRefresh
                onClick={!props.processed ? handleClickProcess : handleClickRefresh}
            >
                {buttonText === "Refresh List" ? <>{buttonText} <LoopIcon sx={{ml: 1}}/></> : buttonText}
            </StyledButton>
        </>
    );
}